@media (max-width: 37.4rem) {
  .style_hide-sm__eDr2k {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__T3RkO {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__32Hmu {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__10KCT {
    display: none !important;
  }
}
.style_container__1Cbqp {
  margin: 1rem 0 0 0;
}
.style_noReleases__GsOKT {
  height: auto;
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #9e9e9e;
}
.style_releaseVersionLink__2FbZ2 {
  font-size: 1.125rem;
  margin: 0;
  padding: 0;
}
.style_releaseDetailsLayout__38N40 {
  padding-bottom: 1rem;
  width: 100%;
  display: flex;
}
.style_releaseDetailsLayoutContent__5H4__ {
  border-right: 0.0625rem solid #cccccc;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.style_releaseDetailsSidebarContent__3v6NN {
  max-width: 260px;
  flex-grow: 1;
}
.style_subTitle__144AC {
  color: #9e9e9e;
}
.style_releaseHeader__3zNt3 {
  font-size: 0.875rem;
}
.style_releaseHeaderVersion__1xLc9 {
  font-size: 1.25rem;
}
.style_dataTableHeaderColumn__8s31m,
.style_dataTableRowColumn__VAAhZ {
  max-width: 16rem;
}
.style_dataTableRowColumn__VAAhZ:first-child {
  width: 9.375rem;
}
.style_dataTableRowColumn__VAAhZ:not(:first-child) {
  width: 16rem;
}
@media (max-width: 37.4rem) {
  .style_releaseDetailsLayout__38N40 {
    flex-direction: column;
  }
  .style_releaseDetailsSidebarContent__3v6NN {
    max-width: 100%;
  }
  .style_subTitle__144AC {
    padding-left: 1rem;
  }
  .style_marginTopBottom__3ndFH {
    margin: 0.5rem 0;
  }
}
.style_releaseNoteLayout__1SlTS,
.style_releaseArtifactsLayout__161sK,
.style_releaseBuildInformationLayout__1kAgs {
  font-size: 0.875rem;
}
.style_releasePackagesLayout__1aUuv {
  font-size: 0.875rem;
}
.style_releasePackagesLayout__1aUuv p {
  margin: 0.5rem !important;
}
.style_releasesTableContainer__19maN .style_filter__2qeEE {
  margin-left: 1rem;
  max-width: 16.25rem;
}
.style_releasesTableContainer__19maN .style_releaseNotesContainer__29YXl {
  cursor: auto;
}
.style_releasesTableContainer__19maN .style_collapseButton__7sfAs {
  margin-left: -0.5rem;
}
.style_releasesTableContainer__19maN .style_notesColumn__2x2JU {
  width: 40rem;
}
.style_deployButtonFilterContainer__2kjHF {
  margin: 0 1rem;
}
.style_deployButtonFilterContainer__2kjHF .style_filterFieldContainer__I3vNO {
  width: 100%;
}
.style_artifactTime__1htcE {
  margin-left: 1rem;
  display: inline-block;
}
