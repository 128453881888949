.PublishButton_container__zj8v7 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.PublishButton_lastPublished__AZOKV {
  font-size: 0.75rem;
  text-align: left;
  font-weight: 300;
  color: #666666;
}
