@media (max-width: 37.4rem) {
  .ActionTemplateCategory_hide-sm__12x5t {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .ActionTemplateCategory_visible-sm__2ulGE {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .ActionTemplateCategory_hide-md__vNj1S {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .ActionTemplateCategory_visible-md__3wXJ6 {
    display: none !important;
  }
}
.ActionTemplateCategory_actionCategory__qmvD7 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 0.25rem;
  height: 6.75rem;
  position: relative;
  user-select: none;
  -ms-user-select: none;
}
.ActionTemplateCategory_isSelectable__2YnmD:hover,
.ActionTemplateCategory_isSelectable__2YnmD:focus {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.ActionTemplateCategory_blurred__2cxfW {
  opacity: 0.2;
}
.ActionTemplateCategory_categoryName__2Vt76 {
  color: #2f93e0;
  text-align: center;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 5.9375rem;
}
.ActionTemplateCategory_active__2EKFz {
  border: 0.125rem solid #2f93e0;
  background-color: #2f93e0;
}
.ActionTemplateCategory_active__2EKFz .ActionTemplateCategory_categoryName__2Vt76 {
  color: #ffffff;
}
.ActionTemplateCategory_active__2EKFz svg {
  fill: #ffffff;
}
.ActionTemplateCategory_inactive__2LTez {
  border: 0.125rem solid transparent;
}
.ActionTemplateCategory_activeIcon__2Tryp {
  position: absolute;
  top: -0.65rem;
  right: -0.65rem;
  background: #ffffff;
  border-radius: 1.875rem;
  height: 1.43rem;
  width: 1.43rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ActionTemplateCategory_activeIcon__2Tryp svg {
  margin-top: 0.0625rem;
}
