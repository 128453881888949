.style_listItem__QJp2D {
  padding: 1.25rem;
  font-size: 0.875rem !important;
}
.style_listItem__QJp2D > div:first-child {
  padding: 0!important;
}
.style_listItem__QJp2D > div:first-child > div:first-child {
  padding: 0.875rem 1rem !important;
}
.style_emptyList__3e8jB {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #9e9e9e;
}
.style_pagingControlContainer__1FuUO {
  margin: 1.5rem 1rem;
}
.style_disablePagingEvents__pMZ-- {
  pointer-events: none;
}
.style_loadMoreContainer__1nZE8 {
  padding: 1rem;
}
.style_loadMoreActions__2SCQd {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.style_loadMoreSubText__1zJm2 {
  font-size: 0.75rem;
  color: #9e9e9e;
}
