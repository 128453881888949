.style_menuIcon__3RZg3 {
  font-size: 1.375rem;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #333333;
}
.style_menuIcon__3RZg3:visited {
  color: #333333;
}
.style_menuIcon__3RZg3:hover {
  opacity: 0.5;
}
.style_container__3GMtP {
  display: flex;
  flex-direction: row;
}
.style_container__3GMtP > :nth-child(2) {
  margin-left: 1rem;
}
.style_buttons__BAKnh {
  padding-top: 1rem;
}
