.style_container__2m4Z_ {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.style_container__2m4Z_ > * {
  margin: 1.5rem 0 0 0;
}
.style_content__3Bsrj {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  width: 23.5rem;
  text-align: center;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.style_description__76Jo0 {
  padding: 0 1.5rem 1.5rem 1.5rem;
  width: 100%;
}
.style_back__3L6j3 {
  padding-top: 1.5rem;
}
.style_logo__3kZ7x {
  background-color: #2f93e0;
  color: #ffffff;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
