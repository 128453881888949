.style_container__3tZOs {
  display: flex;
  height: 48px;
  width: 100%;
  max-width: 26.5rem;
  /* iPad friendly */
  min-width: 0;
  align-items: center;
}
.style_content__1vj7Z {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
  letter-spacing: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.style_iconContainer__3ZEXW {
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}
