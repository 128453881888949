.style_ruleIconWrap__Cha2N {
  display: flex;
  justify-content: center;
}
.style_ruleIconWrap__Cha2N em {
  line-height: 1.25rem;
}
.style_validRule__1BTOO {
  color: #48B350;
}
.style_validRuleIcon__1Sudk {
  color: #48B350;
  display: flex;
  justify-content: center;
}
.style_validRuleIcon__1Sudk em {
  line-height: 1.25rem;
}
.style_invalidRule__-58ux {
  color: #db4437;
  text-decoration: line-through;
}
.style_invalidRuleIcon__JLVp7 {
  color: #db4437;
  display: flex;
  justify-content: center;
}
.style_invalidRuleIcon__JLVp7 em {
  line-height: 1.25rem;
}
.style_badRuleIcon__1unDs {
  color: #fb8c00;
  display: flex;
  justify-content: center;
}
.style_badRuleIcon__1unDs em {
  line-height: 1.25rem;
}
.style_badRuleIcon__1unDs {
  color: #fb8c00;
  display: flex;
  justify-content: center;
}
.style_badRuleIcon__1unDs em {
  line-height: 1.25rem;
}
.style_badRule__15W0X {
  color: #fb8c00;
}
.style_codeGutter__15Etr {
  width: 1.375rem;
}
.style_tooltip__2gTIq {
  background-color: #666666;
  border-color: #666666 transparent transparent transparent;
  border-width: 0.3125rem;
  border-style: solid;
  border-radius: 0.25rem;
  color: #ffffff;
  font-family: monospace;
  font-size: 10pt;
  overflow: hidden;
  padding: 0.125rem 0.3125rem;
  position: fixed;
  white-space: pre;
  white-space: pre-wrap;
  z-index: 2000;
  max-width: 600px;
  opacity: 0;
  transition: opacity 0.4s;
}
