.style_box__2cnCq {
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: #333333;
}
.style_information__3Yh9G {
  background: rgba(8, 120, 209, 0.1);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: #333333;
}
.style_information__3Yh9G .style_title__2KHFV {
  color: #0878D1;
}
.style_success__m2qEC {
  background: rgba(72, 179, 80, 0.1);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: #333333;
}
.style_success__m2qEC .style_title__2KHFV {
  color: #48B350;
}
.style_warning__k92Ea {
  background: rgba(251, 140, 0, 0.1);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: #333333;
}
.style_warning__k92Ea .style_title__2KHFV {
  color: #fb8c00;
}
.style_danger__vx8Ek {
  background: rgba(219, 68, 55, 0.1);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: #333333;
}
.style_danger__vx8Ek .style_title__2KHFV {
  color: #db4437;
}
.style_newFeature__D2U3N {
  background: rgba(47, 147, 224, 0.1);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: #333333;
}
.style_newFeature__D2U3N .style_title__2KHFV {
  color: #2f93e0;
}
.style_none__3uvNB {
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: #333333;
}
.style_title__2KHFV + div {
  margin-top: 0.25rem;
}
.style_title__2KHFV > .style_note__3rjlO {
  margin-bottom: 0;
}
