@media (max-width: 37.4rem) {
  .style_hide-sm__300zN {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1KCya {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2YlDJ {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2cPIY {
    display: none !important;
  }
}
.style_popoverMenu__1JFDf a {
  color: #ffffff;
}
.style_pagingControlButton__2Mxcy {
  cursor: pointer;
  border: 0;
  padding: 0 0.25rem;
  margin: 0 0.0625rem;
  min-width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.125rem;
  background-color: #ffffff;
  color: #9e9e9e;
}
@media (max-width: 37.4rem) {
  .style_pagingControlButton__2Mxcy {
    width: 2rem;
    height: 2rem;
  }
}
.style_pagingControlButton__2Mxcy:hover,
.style_pagingControlButton__2Mxcy:focus {
  background-color: #9e9e9e;
  color: #ffffff;
  outline: 0;
}
.style_pagingControlButton__2Mxcy:active {
  background-color: #ffffff;
  color: #9e9e9e;
}
.style_pagingControlButton__2Mxcy .style_pagingControlButtonText__2XzNo {
  width: 0.5rem;
  height: 1.25rem;
  font-family: Roboto;
  font-size: 0.75rem;
  /* mark.siedle: This differs from the styleguide on purpose ... in double digits 14px looked too crammed */
  line-height: 1.36rem;
  text-align: left;
}
.style_pagingControlButtonActive__1wbLV {
  background-color: #9e9e9e;
  color: #ffffff;
}
.style_pagingControlButtonInactive__3eZmh {
  background-color: #ffffff;
  color: #9e9e9e;
}
.style_buttonWrapper__2NB0T {
  display: inline-block;
}
.style_globalActionButton__2mAxn {
  height: 2.25rem;
  font-size: initial !important;
  /* mark.siedle - Needed to stop MaterialUI setting this as 'inherit' which slightly offsets things from aligning horizontally next to text. */
  min-width: 5rem!important;
  white-space: nowrap !important;
  /* mark.siedle - Needed to stop buttons wrapping when HTML gets squished (ie. Task "Try again" button) */
  align-items: center;
  display: flex;
}
.style_globalActionButton__2mAxn svg {
  fill: #2f93e0 !important;
  margin-right: 0.25rem;
}
.style_primaryButton__30QNp {
  height: 2.25rem;
}
.style_primaryButton__30QNp svg {
  fill: #ffffff !important;
}
.style_primaryButton__30QNp svg + span {
  padding-left: 0.25rem !important;
}
.style_secondaryButton__2KOA8 {
  margin-right: 0.5rem !important;
  line-height: 2.25rem !important;
  background-color: #ecf5fb !important;
  border: 1px solid rgba(47, 147, 224, 0.1) !important;
}
.style_secondaryButton__2KOA8 svg + span {
  padding-left: 0.25rem !important;
}
.style_secondaryButton__2KOA8:hover {
  border: 1px solid #2f93e0 !important;
}
.style_ternaryButton___3j51 {
  background-color: transparent !important;
  color: #2f93e0 !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 3.125rem !important;
}
.style_ternaryButton___3j51 span {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  text-transform: uppercase;
}
.style_ternaryButton___3j51 svg {
  fill: #2f93e0 !important;
  margin-right: 0.5rem;
}
.style_ternaryButton___3j51 svg + span {
  padding-left: 0.25rem !important;
}
.style_ternaryButton___3j51 img + span {
  padding-left: 0.25rem !important;
}
.style_ternaryButton___3j51:hover {
  color: #0A67B1 !important;
}
.style_ternaryButton___3j51:hover svg {
  fill: #0A67B1 !important;
}
.style_disabled__2pMHf {
  font-weight: 900;
  border: 1px solid #e0e0e0 !important;
  height: 2.25rem;
  background-color: rgba(153, 153, 153, 0.08) !important;
  color: #9e9e9e !important;
}
.style_disabled__2pMHf svg {
  fill: #ffffff !important;
}
.style_disabled__2pMHf:hover {
  border: 1px solid #e0e0e0 !important;
  cursor: not-allowed !important;
}
.style_disabled__2pMHf:hover :first-child {
  cursor: not-allowed !important;
}
.style_ternaryDisabled__2WalR {
  font-weight: 900;
  height: 2.25rem;
  color: #9e9e9e !important;
}
.style_ternaryDisabled__2WalR svg {
  fill: #ffffff !important;
}
.style_ternaryDisabled__2WalR:hover {
  cursor: not-allowed !important;
  color: #9e9e9e !important;
}
.style_ternaryDisabled__2WalR:hover :first-child {
  cursor: not-allowed !important;
}
