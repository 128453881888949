.style_menuIcon__CiMJA {
  font-size: 1.375rem;
  height: 2.25rem !important;
  padding: 0!important;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333333;
}
.style_menuIcon__CiMJA:visited {
  color: #333333;
}
.style_menuIcon__CiMJA:hover {
  opacity: 0.5;
}
.style_disabledItem__35CES {
  cursor: not-allowed;
  color: #9e9e9e;
}
