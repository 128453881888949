.RunbookSnapshotEdit_packageTableContainer__24mvs {
  overflow-x: auto;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 {
  font-size: 1rem;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 th {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_packageTableRowColumn__17pYL {
  vertical-align: middle !important;
  padding: 0.25rem 1.125rem;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_packageTableRowColumnName__Z15O5 {
  min-width: 10rem;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_packageColumn__1A1iR {
  width: 25%;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_latestColumn__bJK6Q {
  width: 23%;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_lastColumn__VX5gc {
  width: 23%;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_specificColumn__t1Wsg {
  width: 29%;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_versionTextbox__2be7q {
  width: 18rem !important;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_specificVersionDiv__33hYJ {
  display: flex;
  align-items: center;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_inlineDiv__1a115 {
  display: inline-block;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_inlineDiv__1a115 .RunbookSnapshotEdit_editVersionArea__3su1q {
  min-width: 6.875rem;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_radioButtonContainer__pS_RI {
  margin-top: -0.6rem;
}
.RunbookSnapshotEdit_packageTableContainer__24mvs .RunbookSnapshotEdit_packageTable__3dOG3 .RunbookSnapshotEdit_radioButtonContainer__pS_RI .RunbookSnapshotEdit_myRadioButton__2rzdH {
  margin-right: 0.5rem;
}
.RunbookSnapshotEdit_actionName__3RQ79 {
  font-size: 0.875rem;
  color: #9e9e9e;
}
