@media (max-width: 37.4rem) {
  .style_hide-sm__2dmIg {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1yERH {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__14lf3 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__oglKy {
    display: none !important;
  }
}
.style_container__2a1ln {
  margin: auto;
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.style_content__3qhqb {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background-color: #ffffff;
  border-radius: 0.25rem;
  overflow: hidden;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_content__3qhqb form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 1.5rem 0;
}
.style_rememberMe__2JZe0 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.style_logo__2z6_3 {
  color: #ffffff;
  font-size: 2rem;
  background-color: #2f93e0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.style_logo__2z6_3 h4 {
  margin: 0 0 1rem 0 !important;
}
.style_logo__2z6_3 > div:first-child {
  margin: 1rem 0;
}
.style_externalAuthProvider__2-Iq7 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  width: 18rem;
  margin-left: auto;
  margin-right: auto;
}
.style_externalNonFormsProviders__3reCO {
  background: rgba(153, 153, 153, 0.08);
  padding: 1rem 0;
  width: 100%;
}
.style_guestProvider__2mmZj {
  text-align: center;
  margin: 1rem auto;
  cursor: pointer;
  color: #2f93e0;
}
.style_authenticationError__1h5M_ {
  width: 100%;
}
.style_accountTypeSelection__IsI1G {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (max-width: 37.4rem) {
  .style_accountTypeSelection__IsI1G {
    flex-direction: column;
  }
}
.style_guestAccount__2VFqb {
  background-color: #ffffff;
  color: #2f93e0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  width: 19rem;
  height: 19rem;
  padding-top: 5.5rem;
  text-align: center;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.style_guestAccount__2VFqb .style_guestAccountIcon__2G2wg {
  background-image: url(starfish.790888107b17ffd42ac5f115f1b2c956.hashedasset.svg);
  background-repeat: no-repeat;
  height: 4rem;
  background-position: center;
  margin-bottom: 2rem;
}
.style_authenticatedAccount__298Mq {
  background-color: #2f93e0;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  width: 19rem;
  height: 19rem;
  padding-top: 5.5rem;
  text-align: center;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.style_inProgressMessage__3jIr5 {
  text-align: center;
}
.style_authenticatedAccountIcon__2jtc3 {
  background-image: url(octopus.9edfb27b59f3259a3ec6501e41aa6748.hashedasset.svg);
  background-repeat: no-repeat;
  height: 4rem;
  background-position: center;
  margin-bottom: 2rem;
}
.style_areas__pURvZ > div {
  opacity: 1;
  transition: all 0.5s linear;
}
.style_areas__pURvZ:hover > .style_guestAccount__2VFqb:hover,
.style_areas__pURvZ:hover > .style_authenticatedAccount__298Mq:hover {
  opacity: 1;
  transition: all 0.5s linear;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.style_areas__pURvZ:hover > .style_guestAccount__2VFqb:not(:hover),
.style_areas__pURvZ:hover > .style_authenticatedAccount__298Mq:not(:hover) {
  opacity: 0.5;
  transition: all 0.5s linear;
}
.style_loading__t9y-F {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.style_loading__t9y-F img {
  width: 18.75rem;
  height: 6.976875rem;
  margin-top: 2rem;
}
.style_errorInfoPanel__2xrvg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.style_errorInfoPanelDetails__3jAx9 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  width: 100%;
  text-align: left;
  background-color: rgba(153, 153, 153, 0.08);
  padding: 0.5rem;
}
