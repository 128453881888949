.style_container__hlfWp {
  margin: auto;
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.style_content__DiJMG {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background-color: #ffffff;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_content__DiJMG form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 1.5rem 0;
}
.style_rememberMe__1usLa {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.style_logo__8YUEY {
  color: #ffffff;
  font-size: 2rem;
  background-color: #2f93e0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.style_logo__8YUEY h4 {
  margin: 0 0 1rem 0 !important;
}
.style_logo__8YUEY > div:first-child {
  margin: 1rem 0;
}
.style_authenticationError__2ycF7 {
  width: 100%;
}
.style_accountTypeSelection__3XS9e {
  display: flex;
  width: 38rem;
  height: 19rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.style_authenticatedAccount__3oYco {
  background-color: #2f93e0;
  color: white;
  cursor: pointer;
  width: 50%;
  padding-top: 5.5rem;
  text-align: center;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.style_waitingForOctopus__1eFqD {
  margin: auto;
  width: 22rem;
  position: relative;
  display: block;
  vertical-align: middle;
  text-align: center;
}
.style_waitingForOctopus__1eFqD > div {
  text-align: center;
}
.style_waitingLogo__3qzL1 {
  color: #2f93e0;
  margin: 0 auto;
  font-size: 4rem;
  width: 60px;
  height: 63px;
  object-fit: contain;
  position: relative;
  bottom: 0;
  z-index: 2;
  margin-bottom: 20px;
}
.style_authenticatedAccountIcon__23YRm {
  background-image: url(octopus.9edfb27b59f3259a3ec6501e41aa6748.hashedasset.svg);
  background-repeat: no-repeat;
  height: 4rem;
  background-position: center;
  margin-bottom: 2rem;
}
.style_areas__QM0Pv > div {
  opacity: 1;
  transition: all 0.5s linear;
}
.style_areas__QM0Pv:hover > .style_guestAccount__c16wp:hover,
.style_areas__QM0Pv:hover > .style_authenticatedAccount__3oYco:hover {
  opacity: 1;
  transition: all 0.5s linear;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.style_areas__QM0Pv:hover > .style_guestAccount__c16wp:not(:hover),
.style_areas__QM0Pv:hover > .style_authenticatedAccount__3oYco:not(:hover) {
  opacity: 0.5;
  transition: all 0.5s linear;
}
.style_footerActions__2YhXD {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
.style_loading__2WMSv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.style_loading__2WMSv img {
  width: 18.75rem;
  height: 6.976875rem;
  margin-top: 2rem;
}
