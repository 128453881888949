@media (max-width: 37.4rem) {
  .GettingStartedFooter_hide-sm__rNwpj {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .GettingStartedFooter_visible-sm__2NX14 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .GettingStartedFooter_hide-md__3mHIv {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .GettingStartedFooter_visible-md__1h2J8 {
    display: none !important;
  }
}
.GettingStartedFooter_drawerOffset__2kWqE {
  right: 18.75rem !important;
}
.GettingStartedFooter_drawerOffsetForFooter__3F78M {
  right: 18rem!important;
}
.GettingStartedFooter_gettingStartedFooter__3nEp8 {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  height: auto;
  width: auto;
  background: #ffffff;
  z-index: 11;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.12), 0 0 0.125rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.25rem;
  padding: 0.25rem;
}
@media (max-width: 79.9rem) {
  .GettingStartedFooter_gettingStartedFooter__3nEp8 {
    margin-left: 1rem;
  }
}
.GettingStartedFooter_gettingStartedFooter__3nEp8 .GettingStartedFooter_firstSuccessfulDeploymentContainer__19ySb {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.GettingStartedFooter_gettingStartedFooter__3nEp8 .GettingStartedFooter_firstSuccessfulDeploymentContainer__19ySb .GettingStartedFooter_firstSuccessfulDeployment__3ZgCF {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  padding: 0 1rem 1rem 1rem;
  max-width: 40rem;
}
@media (max-width: 37.4rem) {
  .GettingStartedFooter_gettingStartedFooter__3nEp8 .GettingStartedFooter_firstSuccessfulDeploymentContainer__19ySb {
    flex-direction: column;
  }
}
.GettingStartedFooter_gettingStartedFooter__3nEp8 .GettingStartedFooter_gettingStartedFooterActions__3MTAy {
  position: fixed;
  margin: 0.125rem;
  right: 0.25rem;
  bottom: 0.25rem;
  z-index: 2;
}
.GettingStartedFooter_gettingStartedFooter__3nEp8 .GettingStartedFooter_gettingStartedFooterActions__3MTAy .GettingStartedFooter_gettingStartedButton__116bE {
  color: #ffffff;
  min-width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  padding: 0.1875rem;
  margin-bottom: 2px;
  display: block;
  text-align: right;
  cursor: pointer;
  background-color: #48B350;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.12);
}
.GettingStartedFooter_gettingStartedFooter__3nEp8 .GettingStartedFooter_gettingStartedFooterActions__3MTAy .GettingStartedFooter_gettingStartedButton__116bE em {
  font-size: 1.25rem;
}
.GettingStartedFooter_gettingStartedFooter__3nEp8 .GettingStartedFooter_gettingStartedFooterActions__3MTAy .GettingStartedFooter_gettingStartedButton__116bE span {
  font-size: 1rem;
  padding: 0 1rem;
  vertical-align: middle;
}
.GettingStartedFooter_externalLinkContainer__2x64_ {
  margin: 1rem 0;
}
.GettingStartedFooter_successImage__v2mQa {
  height: auto;
  width: 300px;
  margin: 0.5rem;
  justify-content: center;
  display: flex;
  align-self: flex-end;
}
.GettingStartedFooter_intensePulse__2GQia {
  animation: GettingStartedFooter_intensePulse__2GQia 2s infinite;
}
@-webkit-keyframes GettingStartedFooter_intensePulse__2GQia {
  0% {
    -webkit-box-shadow: 0 0 0 0 #48b350;
  }
  70% {
    -webkit-box-shadow: 0 0 0 0.75rem rgba(72, 179, 80, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(72, 179, 80, 0);
  }
}
@keyframes GettingStartedFooter_intensePulse__2GQia {
  0% {
    -moz-box-shadow: 0 0 0 0 #48b350;
    box-shadow: 0 0 0 0 #48b350;
  }
  70% {
    -moz-box-shadow: 0 0 0 0.75rem rgba(72, 179, 80, 0);
    box-shadow: 0 0 0 0.75rem rgba(72, 179, 80, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(72, 179, 80, 0);
    box-shadow: 0 0 0 0 rgba(72, 179, 80, 0);
  }
}
.GettingStartedFooter_lightPulse__wjD4G {
  animation: GettingStartedFooter_lightPulse__wjD4G 2s infinite;
}
@-webkit-keyframes GettingStartedFooter_lightPulse__wjD4G {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(72, 179, 80, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 1rem rgba(72, 179, 80, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(72, 179, 80, 0);
  }
}
@keyframes GettingStartedFooter_lightPulse__wjD4G {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(72, 179, 80, 0.6);
    box-shadow: 0 0 0 0 rgba(72, 179, 80, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 0.5rem rgba(72, 179, 80, 0);
    box-shadow: 0 0 0 1rem rgba(72, 179, 80, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(72, 179, 80, 0);
    box-shadow: 0 0 0 0 rgba(72, 179, 80, 0);
  }
}
