@media (max-width: 37.4rem) {
  .GettingStartedDetails_hide-sm__2coac {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .GettingStartedDetails_visible-sm__1QBlE {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .GettingStartedDetails_hide-md__1W0M_ {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .GettingStartedDetails_visible-md__1kfHP {
    display: none !important;
  }
}
@font-face {
  font-family: 'fontoctopus';
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot);
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot#iefix) format('embedded-opentype'), url(fontoctopus.8acc0c7e45d7d6f690a7342d70180835.hashedasset.woff) format('woff'), url(fontoctopus.5eed1ba76b15097fa82835184a007327.hashedasset.ttf) format('truetype'), url(fontoctopus.84200d09800b6f6d6b36c793c47c0be5.hashedasset.svg) format('svg');
  font-weight: 400;
  font-style: normal;
}
.GettingStartedDetails_fontoctopus-octopus__2HPQN {
  display: inline-block;
  font: normal normal normal 1em fontoctopus;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}
.GettingStartedDetails_fontoctopus-octopus__2HPQN:before {
  content: '\e800';
}
/* '' */
.GettingStartedDetails_li-style__1Uto4 {
  font-family: FontAwesome;
  display: inline-block;
  padding-right: 5px;
}
.GettingStartedDetails_container__1YnyF {
  width: 32.5rem;
  max-width: 37.5rem;
  min-width: 20rem;
  background: #ffffff;
  color: #333333;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.GettingStartedDetails_container__1YnyF .GettingStartedDetails_header__3wEqt .GettingStartedDetails_title__Qn3zh {
  padding: 1rem;
  font-size: 1.063rem;
  font-weight: 500;
}
.GettingStartedDetails_container__1YnyF .GettingStartedDetails_header__3wEqt h2 {
  text-align: center;
  width: 100%;
}
.GettingStartedDetails_container__1YnyF > div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-self: stretch;
  width: auto;
  counter-reset: section;
}
@media (max-width: 37.4rem) {
  .GettingStartedDetails_container__1YnyF {
    padding: 0;
    width: auto;
  }
}
.GettingStartedDetails_liftBusyIndicator__aeGnG div:first-child {
  padding-top: -1.5rem;
}
.GettingStartedDetails_stepContent__QAsGI {
  padding-top: 0.5rem;
}
.GettingStartedDetails_footer__114B8 {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(153, 153, 153, 0.08);
}
.GettingStartedDetails_footer__114B8 div {
  text-align: center;
}
.GettingStartedDetails_actionLinkRow__3BZ36 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0;
}
.GettingStartedDetails_actionLinkRow__3BZ36 .GettingStartedDetails_actionLinkRowContent__3mtmc {
  margin-left: 0.5rem;
}
.GettingStartedDetails_actionLinkRow__3BZ36 svg {
  transform: scale(0.9);
}
.GettingStartedDetails_gettingStartedSection__3wJJd {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
}
.GettingStartedDetails_gettingStartedIntro__2U09p {
  text-align: left;
  padding-left: 4rem;
  margin: 1rem 0 0.5rem;
  color: #333333;
  font-size: 14px;
  text-indent: -30px;
}
.GettingStartedDetails_gettingStartedIntro__2U09p:before {
  font-family: fontoctopus;
  color: white;
  content: '\e800';
  position: relative;
  left: -0.5rem;
  top: 0.7rem;
  padding: 0.5rem;
  background-color: #9e9e9e;
  border-radius: 20px;
}
.GettingStartedDetails_gettingStartedIntro__2U09p:after {
  content: '.';
  height: 30px;
  position: relative;
  left: -12.7rem;
  top: 1.2rem;
  border-right: 1px solid #9e9e9e;
  color: #ffffff;
}
.GettingStartedDetails_stepperContainer__AvFAm {
  padding: 0 1rem 1rem 1rem;
  margin-right: 1.75rem;
  /* mark.siedle: hacking MaterialUI, possibly going to hell for this but #YOLO */
  /* Lifted from MUI's stepper styles */
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonActive__2BlJO {
  /* Targeting the icon this way because iconContainerStyle doesn't work in IE11 */
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonActive__2BlJO div span {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonActive__2BlJO div span span {
  transform: scale(1.6);
  margin-left: 2px;
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonActive__2BlJO div span svg {
  color: #48B350 !important;
  fill: #48B350 !important;
}
@media (max-width: 37.4rem) {
  .GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonActive__2BlJO {
    text-align: left;
  }
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonInActive__1nP5o {
  /* Targeting the icon this way because iconContainerStyle doesn't work in IE11 */
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonInActive__1nP5o div span {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonInActive__1nP5o div span span {
  transform: initial;
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonInActive__1nP5o div span svg {
  color: #9e9e9e !important;
  fill: #9e9e9e !important;
}
@media (max-width: 37.4rem) {
  .GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonInActive__1nP5o {
    text-align: left;
  }
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonIconComplete__8uy3K div span svg {
  color: #48B350 !important;
  fill: #48B350 !important;
  opacity: 0.4;
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepperIcon__3z5sa {
  /* mark.siedle: hacking MaterialUI (this is fun) =P */
  transform: scale(0.9);
}
.GettingStartedDetails_stepperContainer__AvFAm .GettingStartedDetails_stepButtonCustomCircleIndex__1xlbV {
  display: block;
  color: #48b350;
  fill: #48b350;
  height: 24px;
  width: 24px;
  user-select: none;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  font-size: 24px;
}
.GettingStartedDetails_overviewDialog__1tx9d > div {
  width: 800px !important;
}
.GettingStartedDetails_section__3WY-X {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #666666;
  margin: 1rem 0 0.25rem 0;
}
.GettingStartedDetails_heading__3grhO {
  font-size: 1.5rem;
  color: #333333;
  font-weight: 400;
  margin-top: 1rem;
}
.GettingStartedDetails_content__JYPer {
  font-size: 0.875rem;
  color: #666666;
  margin: 0.5rem 0;
}
.GettingStartedDetails_content__JYPer ol li {
  list-style-type: decimal;
  margin-left: 1rem;
  font-weight: 600;
}
.GettingStartedDetails_content__JYPer ol li p {
  font-weight: 400;
  font-size: 14px;
  margin-top: 0;
  color: #666666;
  line-height: 1.5em;
}
.GettingStartedDetails_overviewImage__2g14g {
  width: 60%;
  text-align: center;
}
