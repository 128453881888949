@media (max-width: 37.4rem) {
  .projectSwitcher_hide-sm__219Dr {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .projectSwitcher_visible-sm__CtS2N {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .projectSwitcher_hide-md__qykFK {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .projectSwitcher_visible-md__3wuY6 {
    display: none !important;
  }
}
.projectSwitcher_container__1MyF5 {
  display: flex;
  width: auto;
  overflow-y: hidden;
  min-height: 31.4375rem;
}
.projectSwitcher_recentlyViewedSection__1ccmO {
  width: 21.875rem;
}
@media (max-width: 37.4rem) {
  .projectSwitcher_recentlyViewedSection__1ccmO {
    width: 100%;
  }
}
.projectSwitcher_allProjectsList__7KULh {
  width: 25rem;
}
.projectSwitcher_menuContainer__2MrWv {
  overflow-y: auto;
  height: 25rem;
}
.projectSwitcher_filterFieldContainer__2t3nq {
  width: 100%;
}
.projectSwitcher_filterContainer__otVay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.projectSwitcher_refreshIcon__UD62V {
  width: 3rem !important;
  height: 3rem !important;
}
.projectSwitcher_groupName__2-CWo {
  background-color: rgba(153, 153, 153, 0.08);
  padding: 0.5rem 1.125rem;
  font-size: 0.9375rem;
}
.projectSwitcher_empty__2zGYF {
  padding: 0.625rem;
}
.projectSwitcher_warning__3w14l {
  margin: 0.5rem 1rem;
  color: #fb8c00;
  font-size: 0.75rem;
}
