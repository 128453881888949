@media (max-width: 37.4rem) {
  .spaceSwitcher_hide-sm__3WNZv {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .spaceSwitcher_visible-sm__1znIT {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .spaceSwitcher_hide-md__mtGTS {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .spaceSwitcher_visible-md__3FkTY {
    display: none !important;
  }
}
.spaceSwitcher_container__28GuI {
  display: flex;
  flex-direction: column;
  width: 25rem;
}
.spaceSwitcher_menuContainer__7q3Gi {
  overflow-y: auto;
  height: 25rem;
}
.spaceSwitcher_filterFieldContainer__vdyGy {
  width: 100%;
}
.spaceSwitcher_selected__3Ieco {
  padding: 1rem;
  background-color: #0F2535;
  color: #ffffff;
  word-break: break-all;
  font-weight: 500;
  display: none;
}
@media (max-width: 37.4rem) {
  .spaceSwitcher_selected__3Ieco {
    display: initial;
  }
}
.spaceSwitcher_rowContainer__1uj2g {
  display: flex;
  align-items: center;
}
.spaceSwitcher_rowLogo__2YVDm {
  padding-right: 0.4375rem;
}
.spaceSwitcher_rowTextContainer__2p3Rf {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.spaceSwitcher_emptyButton__1NUoS {
  margin-bottom: 0.9375rem;
}
.spaceSwitcher_rowTextTitle__3XiUK {
  word-break: break-all;
}
.spaceSwitcher_rowTextDescription__d38_k {
  max-height: 2.5rem;
  color: #666666;
  padding-top: 0.25rem;
  word-break: break-all;
  font-size: 0.875rem;
}
.spaceSwitcher_empty__cK4Ul {
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.spaceSwitcher_description__1UsVZ {
  width: 21.875rem;
}
.spaceSwitcher_footer__2nRr7 {
  padding: 0.5rem 2rem 0.5rem;
  background-color: #ffffff;
  color: #333333;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spaceSwitcher_selectedIndicator__2X1R0 > div .spaceSwitcher_rowTextTitle__3XiUK {
  color: #2f93e0;
  font-weight: 500;
}
